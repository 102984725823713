import React from 'react';
import Layout from '../components/Layout';
import Container from '../components/Container';

const JobsPage = () => (
  <Layout>
    <Container>
      <h1>Jobba hos oss</h1>
      <p>
      Mitt Skolval är Sveriges ledande aktör inom skolval och skolplacering. Vi är ett idédrivet företag grundat av några av Sveriges främsta skolvalsforskare och vår målsättning är att göra skolvalet mer rättvist, rättssäkert, transparent, och effektivt. Detta gör vi främst genom en SaaS-plattform där kommuner och fristående skolor kan hantera alla delar av antagningen till sina skolor. Läs mer om vad vi på Mitt Skolval gör under Om oss. 
      <br/><br/>Arbetet i Mitt Skolval är mycket flexibelt och varierat. Man har stort eget ansvar men ingår i ett sammansvetsat och kul team med bra gemenskap. Takten är hög men vi tar hand om varandra och har roligt ihop. Vi jobbar med samhällsviktiga frågor i en forskningsnära verksamhet, där ens arbete har en direkt positiv påverkan på många människors liv och på samhället i stort.
      <br/><br/> Idag är vi knappt 10 personer som jobbar heltid i Mitt Skolval med utgångspunkt från vårt kontor på Dalagatan centralt i Stockholm. Just nu letar vi inte aktivt efter en ny medarbetare men du är alltid välkommen att höra av dig till oss om du skulle vilja jobba här. Skicka ett mejl till <a href="mailto:jobb@mittskolval.se">jobb@mittskolval.se</a>!
      </p>
    </Container>
  </Layout>
);

export default JobsPage;
